import React, { useState } from "react";
import "../Styles/Info.css";
import { Button, Slider } from "@mui/material";
import Image4 from "../Assets/My_imgs/Frame_1.png";
import Image5 from "../Assets/My_imgs/Image_ref1.png";
import Image6 from "../Assets/My_imgs/Image_ref2.png";
import Image7 from "../Assets/My_imgs/Image_ref3.png";
import Image8 from "../Assets/My_imgs/green_2bg.png";
import Image9 from "../Assets/My_imgs/home_person.png";
import Image10 from "../Assets/My_imgs/Chit cycle.png";
import Image11 from "../Assets/My_imgs/cal_img.png";

function Info() {
  const [activeButton, setActiveButton] = useState("");
  const [showValues, setShowValues] = useState(true);
  const [showValuesOne, setShowValuesOne] = useState(false);
  const [showValuesTwo, setShowValuesTwo] = useState(false);
  // income based cal
  const [sliderValue, setSliderValue] = useState(0);
  const [sliderValueOne, setSliderValueOne] = useState(0);
  const [sliderValueTwo, setSliderValueTwo] = useState(0);
  // instalment based cal
  const [sliderValueinstalmentOne, setSliderValueinstalmentOne] = useState(0);
  const [sliderValueinstalmentTwo, setSliderValueinstalmentTwo] = useState(0);
  // amount based cal
  const [sliderValueamountOne, setSliderValueamountOne] = useState(0);
  const [sliderValueamountTwo, setSliderValueamountTwo] = useState(0);

  const handleClick = (buttonName) => {
    setActiveButton(buttonName === activeButton ? "" : buttonName);
    setShowValues(true);
    setShowValuesOne(false);
    setShowValuesTwo(false);
  };

  const handleClickSecondButton = (buttonName) => {
    setActiveButton(buttonName === activeButton ? "" : buttonName);
    setShowValuesOne(true);
    setShowValues(false);
    setShowValuesTwo(false);
  };

  const handleClickThirdButton = (buttonName) => {
    setActiveButton(buttonName === activeButton ? "" : buttonName);
    setShowValuesOne(false);
    setShowValues(false);
    setShowValuesTwo(true);
  };

  const values = [
    "Value 1000",
    "Value 2000",
    "Value 3000",
    "Value 4000",
    "Value 5000",
    "Value 6000",
    "Value 7000",
    "Value 8000",
    "Value 9000",
    "Value 10000",
  ];

  const handleSliderChange = (value, setValue) => (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="carousel-container">
        <div className="background-overlay">
          <img className="background-img" src={Image4} alt="Background" />
          <div className="overlay-text">
            <h2>
              <span className="black-text">Personalised</span>
              <span className="green-text"> Financial Solutions</span>
            </h2>
            <p style={{ fontSize: "20px", width: "50%", marginLeft: "15rem" }}>
              Valli Chits Guides You to Financial Freedom:
            </p>
            <p style={{ fontSize: "20px", width: "70%", marginLeft: "10rem" }}>
              Invest with Confidence, Borrow with Ease, and Partner with Us for
              Success.
            </p>
          </div>
        </div>

        <div className="carousel" style={{ marginTop: "1rem" }}>
          <div className="carousel-item">
            <img src={Image5} alt="Beginner" />
            <p className="text_styles">Beginner</p>
            <button className="view_more_btn">View more</button>
          </div>
          <div className="carousel-item">
            <img src={Image6} alt="Experienced Professionals" />
            <p className="text_styles">Experienced Professionals</p>
            <button className="view_more_btn">View more</button>
          </div>
          <div className="carousel-item">
            <img src={Image7} alt="Senior Citizen" />
            <p className="text_styles">Senior Citizen</p>
            <button className="view_more_btn">View more</button>
          </div>
        </div>
        {/* <button className="prev">&#10094;</button>
        <button className="next">&#10095;</button> */}
      </div>
      <div className="threeDiv">
        <div className="agent-section">
          <div className="agent-content">
            <h2 className="agent-title">Want to become an agent?</h2>
            <p className="agent-subtitle">
              Build a fulfilling career and be your own boss with Valli Chits.
            </p>
            <p className="agent-description">
              Empower yourself and others by helping people achieve financial
              freedom, creating purpose and positive impact.
            </p>
            <button className="join-button">Join us now</button>
          </div>
          <div className="agent-image">
            <img src={Image9} alt="Agent" />
          </div>
        </div>
      </div>
      {/* <div className="info-section" id="services">
        <div className="info-title-content">
          <h3 className="info-title" style={{ marginTop: "1rem" }}>
            <span>Chit Calculators</span>
          </h3>
          <p className="info-description">
            Determine which plan best suits your needs. Input your monthly
            income and let our accurate Chit Calculator do the rest. Select the
            most appropriate Chit Group by inputting your monthly income, the
            monthly installment you wish to pay, or the desired Prize Money.
          </p>
        </div>
        <div
          className="chit_cal_button"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div>
            <Button
              style={{
                backgroundColor: "#1a8efd",
                width: "100%",
              }}
              variant="contained"
              className={`income_buttons ${
                activeButton === "Income" ? "active" : ""
              }`}
              onClick={() => handleClick("Income")}
            >
              Income Based Calculator
            </Button>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#1a8efd",
                width: "100%",
              }}
              variant="contained"
              className={`income ${
                activeButton === "Instalment" ? "active" : ""
              }`}
              onClick={() => handleClickSecondButton("Instalment")}
            >
              Instalment Based Calculator
            </Button>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#1a8efd",
                width: "100% ",
              }}
              variant="contained"
              className={`income ${activeButton === "Amount" ? "active" : ""}`}
              onClick={() => handleClickThirdButton("Amount")}
            >
              Amount Based Calculator
            </Button>
          </div>
        </div>

        {showValues && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              gridAutoRows: "minmax(100px, auto)",
              marginLeft: "2rem",
            }}
          >
            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Month Salary</div>
                <div>{values[sliderValue]}</div>
              </div>
              <Slider
                value={sliderValue}
                onChange={handleSliderChange(sliderValue, setSliderValue)}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>

            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>EMI / Commitments & Exp (₹)</div>
                <div>{values[sliderValueOne]}</div>
              </div>
              <Slider
                value={sliderValueOne}
                onChange={handleSliderChange(sliderValueOne, setSliderValueOne)}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>

            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Surplus Income (₹)</div>
                <div>{values[sliderValueTwo]}</div>
              </div>
              <Slider
                value={sliderValueTwo}
                onChange={handleSliderChange(sliderValueTwo, setSliderValueTwo)}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>
          </div>
        )}

        {showValuesOne && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              gridAutoRows: "minmax(100px, auto)",
              marginLeft: "2rem",
            }}
          >
            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Amount Planned ₹</div>
                <div>{values[sliderValueinstalmentOne]}</div>
              </div>
              <Slider
                value={sliderValueinstalmentOne}
                onChange={handleSliderChange(
                  sliderValueinstalmentOne,
                  setSliderValueinstalmentOne
                )}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>
            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Tenure (Months)</div>
                <div>{values[sliderValueinstalmentTwo]}</div>
              </div>
              <Slider
                value={sliderValueinstalmentTwo}
                onChange={handleSliderChange(
                  sliderValueinstalmentTwo,
                  setSliderValueinstalmentTwo
                )}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>
          </div>
        )}

        {showValuesTwo && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              gridAutoRows: "minmax(100px, auto)",
              marginLeft: "2rem",
            }}
          >
            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Instalment ₹</div>
                <div>{values[sliderValueamountOne]}</div>
              </div>
              <Slider
                value={sliderValueamountOne}
                onChange={handleSliderChange(
                  sliderValueamountOne,
                  setSliderValueamountOne
                )}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>

            <div
              className="values-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "200px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>Month (Months)</div>
                <div>{values[sliderValueamountTwo]}</div>
              </div>
              <Slider
                value={sliderValueamountTwo}
                onChange={handleSliderChange(
                  sliderValueamountTwo,
                  setSliderValueamountTwo
                )}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={values.length - 1}
                style={{ width: "200px", marginTop: "1rem" }}
              />
            </div>
          </div>
        )}

        {/* <div className="info-cards-content">
        <InformationCard
          title="How Does A Chit Fund Work?"
          description="A chit fund involves a group of individuals who contribute fixed monthly amounts for a set period. Each month, the pool of contributions forms a prize fund. Subscribers bid for this fund in a reverse auction, with the lowest bidder winning the prize. After deducting a commission, the remaining amount is distributed among all subscribers. This cycle repeats monthly, providing each member with a chance to win. Chit funds offer a structured way to save, borrow, and earn returns within a community."
          icon={faTruckMedical}
        />

        <InformationCard
          title="Heart Disease"
          description="Our team of experienced cardiologists and medical experts use
            state-of-the-art technology to assess your cardiovascular ValliChitsand
            design personalized treatment plans. From comprehensive screenings
            to advanced interventions, we are committed to helping you maintain
            a chitsy heart and lead a fulfilling life."
          icon={faHeartPulse}
        />

        <InformationCard
          title="Dental Care"
          description="Smile with confidence as our Dental Care services cater to all your
            oral ValliChitsneeds. Our skilled dentists provide a wide range of
            treatments, from routine check-ups and cleanings to cosmetic
            procedures and restorative treatments."
          icon={faTooth}
        />
      </div> */}
      {/* </div> */}

      <div>
        <img
          src={Image10}
          alt="Beginner"
          style={{ width: "100%", marginTop: "1rem" }}
        />
      </div>

      <div className="threeDiv">
        <div className="agent-section">
          <div className="agent-content">
            <h2 style={{ fontSize: "30px", fontFamily: "Poppins" }}>
              Find Your Perfect Chit Fund:
            </h2>
            <p style={{ fontSize: "40px", fontFamily: "Poppins" }}>
              Calculate Your Contribution
            </p>
            <p className="agent-description" style={{ fontSize: "20px" }}>
              Let our accurate Chit Calculator guide you.
            </p>
            <button className="join-button">Calculate now</button>
          </div>
          <div className="agent-image">
            <img src={Image11} alt="Agent" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
