import React from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import Image1 from "../Assets/My_imgs/homeBG_img.png";
import Image2 from "../Assets/My_imgs/side_imgs.png";
import Image3 from "../Assets/My_imgs/green_bg.png";

import { FaArrowRight, FaPhone, FaWhatsapp } from "react-icons/fa";

function Hero() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <>
      <div class="main-image-section">
        <img class="main-image" src={Image1} />
        <div class="image-overlay">
          <h1>Turn Your Savings Goals into Reality</h1>
          <p>Transparent Transactions, Trusted Relationships</p>
          <button class="cta-button">Know More</button>
          <div className="contact-icons">
            <a href="tel:+1234567890" className="icon-link">
              <FaPhone className="icon phone-icon" />
            </a>
            <a href="https://wa.me/1234567890" className="icon-link">
              <FaWhatsapp className="icon whatsapp-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="secondDiv">
        <div>
          <img class="secondDiv-img" src={Image2} />
        </div>
        <div style={{ marginTop: "4rem" }}>
          <div className="line"></div>
          <div className="HeadingText">
            About <span>Valli Chits</span>
          </div>
          <div className="p_text">
            Valli Chits is a leading chit fund company dedicated to empowering
            individuals like you to achieve financial freedom. We offer a secure
            and transparent platform for saving and borrowing, tailored to meet
            your specific needs.
          </div>
          <button class="cta-button" style={{ marginTop: "4rem" }}>
            Know More <FaArrowRight className="arrow-icon" />
          </button>
        </div>
      </div>
      <div className="threeDiv">
        <div>
          <img class="threeDiv-img" src={Image3} />
        </div>
        <div>
          <div className="threeDivHeadingText">
            Your Partner in Financial Free
          </div>
          <div className="threeDivp_text">
            Valli Chits has grown alongside our customers, celebrating their
            success stories with every satisfied member.
          </div>
          <div className="threeDiv-data">
            <div className="threeDiv-clientData">
              <span>30</span>
              <span>Clients</span>
            </div>
            <div className="threeDiv-clientData">
              <span>300+</span>
              <span>Taken business legalities</span>
            </div>
            <div className="threeDiv-clientData">
              <span>8</span>
              <span>Years of Journey</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
